import * as React from 'react';
import PropTypes from 'prop-types';
import {
    useListContext,
    useNotify,
    useRecordContext,
    useUpdate,
} from 'react-admin';
import { CheckCircle, RemoveCircle } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
    active: {
        color: green[500],
    },
    root: {
        textAlign: 'center',
    },
});

const StatusField = props => {
    const classes = useStyles();
    const notify = useNotify();
    const { basePath } = useListContext();
    const { source } = props;
    const record = useRecordContext(props);
    const letActive = e => {
        e.stopPropagation();
        active();
    };
    const [active, { loading }] = useUpdate(
        basePath.substring(1),
        record?.id,
        { ...record, status: 'active' },
        record,
        {
            undoable: true,
            onSuccess: () => {
                notify(`Kích hoạt thành công`, 'info', {}, true);
            },
            onFailure: () => {
                notify('Kích hoạt thất bại', 'warning');
            },
        }
    );
    const letDeactivate = e => {
        e.stopPropagation();
        deactivate();
    };
    const [deactivate, { loading: loading1 }] = useUpdate(
        basePath.substring(1),
        record?.id,
        { ...record, status: 'deactivate' },
        record,
        {
            undoable: true,
            onSuccess: () => {
                notify('Hủy kích hoạt thành công', 'info', {}, true);
            },
            onFailure: () => {
                notify('Hủy kích hoạt thất bại', 'warning');
            },
        }
    );
    if (loading || loading1) {
        return <CircularProgress size={16} />;
    }
    if (!record) {
        return null;
    }
    if (record[source] === 'active') {
        return (
            <CheckCircle className={classes.active} onClick={letDeactivate} />
        );
    }
    return <RemoveCircle color="disabled" onClick={letActive} />;
};

StatusField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

StatusField.defaultProps = {
    source: 'status',
    textAlign: 'center',
};

export default StatusField;
