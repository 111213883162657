// export const BASE_URL = 'http://localhost:11001/gsdtv-api';
export const BASE_URL = 'https://s2t.mobifone8.com.vn/gsdtv-api';

export const resources = [
    {
        id: 'call',
        name: 'Cuộc gọi',
        permissions: ['view', 'add'],
    },
    {
        id: 'callLogs',
        name: 'Logs cuộc gọi',
        permissions: ['view', 'export'],
    },
    {
        id: 'report',
        name: 'Báo cáo',
        permissions: ['export'],
    },
    {
        id: 'user',
        name: 'Người dùng',
        permissions: ['view', 'add', 'edit', 'delete'],
    },
    {
        id: 'group',
        name: 'Nhóm quyền',
        permissions: ['view', 'add', 'edit', 'delete'],
    },
    {
        id: 'telephonistGroup',
        name: 'Nhóm người dùng',
        permissions: ['view', 'add', 'edit', 'delete'],
    },
    {
        id: 'department',
        name: 'Đơn vị',
        permissions: ['view', 'add', 'edit', 'delete'],
    },
    {
        id: 'category',
        name: 'Cấu hình',
        permissions: ['view', 'add', 'edit', 'delete'],
    },
];
