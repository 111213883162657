import { AttachFile, CloudUpload, Publish } from '@material-ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { Button, fetchUtils, useNotify, useRefresh } from 'react-admin';
import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { BASE_URL } from '../common/constants';
import {buildHeader} from "../utils/ultils";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

const useStyles = makeStyles(theme => ({
    dropzone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        '&:focus': {
            borderColor: '#2196f3',
        },
    },
    errorDialog: {
        '& .MuiPaper-root': {
            minWidth: '740px !important'
        }
    }
}));

const ImportButton = props => {
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const [file, setFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openError, setOpenError] = useState(false);
    const [errorData, setErrorData] = useState<any[]>([]);
    const handleClose = () => {
        setFile(null);
        setOpen(false);
    };
    const handleCloseError = () => {
        setOpenError(false)
    }
    const openPopup = () => {
        setOpen(true);
    };
    const handleFile = acceptedFiles => {
        if (acceptedFiles) setFile(acceptedFiles[0]);
    };
    const downloadFileTemplate =()=> {

        const request = new Request(`${BASE_URL}/user/downloadTemplate`, {
            method: 'GET',
            headers: buildHeader()
        })

        fetch(request)
            .then(response => {
                return response.blob();
            })
            .then(response => {
                setLoading(false);
                let fileURL = window.URL.createObjectURL(
                    // @ts-ignore
                    new Blob([response])
                );
                let fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute(
                    'download',
                    `${"Template_import_user.xlsx"}`
                )
                document.body.appendChild(fileLink);
                fileLink.click();
            })
            .catch(({body, ...res}) => {
                notify(body.error, 'error');
            });
    }

    const importFile = () => {
        if (!file) {
            notify('Vui lòng tải file lên', 'error');
            return;
        }
        setLoading(true);
        const data = new FormData();
        // @ts-ignore
        data.append('file', file);
        fetchUtils
            .fetchJson(`${BASE_URL}/user/import/user/`, {
                method: 'POST',
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    LdapType: `${localStorage.getItem('ldap_type')}`,
                }),
                body: data,
            })
            .then(r => {
                setLoading(false);
                notify('Nhập người dùng thành công','success');
                handleClose();
                refresh();
            })
            .catch(({ body }) => {
                setLoading(false);
                if(body.errorCode !== null &&
                    body.errorCode !== undefined &&
                    body.errorCode === 'error.importUser.hasErrorRows' &&
                    Array.isArray(body.params))
                {
                    setErrorData(body.params);
                    setOpenError(true);
                } else {
                    notify('Có lỗi khi import', 'error');
                }
            });
    };

    return (
        <>
            <Button
                variant="text"
                onClick={openPopup}
                startIcon={<Publish />}
                label={'Nhập'}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Nhập người dùng
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                    >
                        Nếu chưa có tệp mẫu ?{' '}
                        <a
                            onClick={downloadFileTemplate}
                        >
                            Tải tại đây
                        </a>
                    </DialogContentText>
                    <Dropzone onDrop={handleFile}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div
                                    {...getRootProps({
                                        className: classes.dropzone,
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    {!file && (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CloudUpload />
                                            <Box ml={1} />
                                            <p>
                                                Kéo thả hoặc nhấp để tải lên
                                                file excel nhập danh sách người
                                                dùng.
                                            </p>
                                        </Box>
                                    )}
                                    {file && (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <AttachFile />
                                            {/*// @ts-ignore*/}
                                            <p>{file.name}</p>
                                        </Box>
                                    )}
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </DialogContent>
                <DialogActions>
                    <Box mx={2} my={1}>
                        <Button
                            onClick={importFile}
                            variant="contained"
                            color="primary"
                            label={'Xác nhận'}
                            disabled={loading}
                            startIcon={
                                loading ? <CircularProgress size={16} /> : null
                            }
                        />
                        <Button
                            onClick={handleClose}
                            color="primary"
                            label={'Cancel'}
                        />
                    </Box>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openError}
                onClose={handleCloseError}
                className={classes.errorDialog}
            >
                <DialogTitle>
                    Lỗi import
                </DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right">Dòng</TableCell>
                                    <TableCell align="right">Lỗi</TableCell>
                                    <TableCell align="right">Lý do</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {errorData.map((row) => (
                                    <TableRow
                                        key={row.line}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="right">{row.line}</TableCell>
                                        <TableCell align="right">{row.params}</TableCell>
                                        <TableCell align="right">{row.errorMessage}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Box mx={2} my={1}>
                        <Button
                            onClick={handleCloseError}
                            color="primary"
                            label={'Cancel'}
                        />
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ImportButton;
